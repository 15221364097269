<template>
  <div class="columns is-multiline">
    <div class="column is-12">
      <p>
        Check the options on this {{ make }} to unlock a better understanding of
        the unique aspects which contribute to its value.
      </p>
    </div>
    <div v-if="optionsUnavailable" class="column is-12">
      <p class="help has-text-danger">
        <span class="icon">
          <i class="fal fa-exclamation-circle" />
        </span>
        <span>
          The factory fitted options could not be found for this vehicle at this
          time
        </span>
      </p>
    </div>
    <div class="column is-12">
      <div class="buttons is-right">
        <button class="button is-text" @click="$modal.close">
          Cancel
        </button>
        <button
          :class="{ 'is-loading': $wait.is('getOptions') }"
          class="button is-rounded is-info"
          @click="getOptions"
        >
          View options
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  name: 'CompanionOptionsConfirm',
  computed: {
    ...mapGetters('companion/options', ['optionsData', 'make']),
    optionsUnavailable() {
      return this.optionsData === false
    }
  },
  methods: {
    getOptions() {
      this.$mxp.track('companion_options_confirm')
      this.$emit('get-options')
    }
  }
}
</script>
